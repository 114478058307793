<template>
  <div class="card-reference-payment">
    <p>
      <strong>Entidade:</strong> <span>{{ referencePayment.entidade }}</span>
    </p>
    <p>
      <strong>Referência:</strong>
      <span>{{ referencePayment.referenciaF }}</span>
    </p>
    <p>
      <strong>Valor:</strong> <span>{{ referencePayment.valorF }}</span>
    </p>
    <p>
      <strong>Data Limite:</strong>
      <span>{{ referencePayment.dataLimiteF }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'app-card-referece-payment',
  props: {
    referencePayment: Object
  }
}
</script>
<style lang="scss" scoped>
.card-reference-payment {
  border: 1px solid #4B335A;
  padding: 12px 18px;
  border-radius: 20px;
  margin: 40px 0;
  min-width: 220px;
  p {
    margin: 6px 0;
    display: flex;
    justify-content: space-between;
  }
  strong {
    font-size: 12px;
    font-weight: 600;
  }
  span {
    font-size: 14px;
  }
}
</style>
