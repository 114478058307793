<template>
  <main>
    <form-loading v-show="loading" :title="loadingTitle" />
    <modal-data-academic v-if="dataAcademicInsertToggle"
    @closemodal="dataAcademicInsertToggle = false"
    @pushEducacao="(data) => minhaArea.educacoes.push(data)"
    />
    <modal-data-formacao v-else-if="dataFormacaoInsertToggle"
    @closemodal="dataFormacaoInsertToggle = false"
    @pushItem="(data) => minhaArea.formacoes.push(data)"
    />
    <modal-data-experiencia v-else-if="dataExperienciaInsertToggle"
    @closemodal="dataExperienciaInsertToggle = false"
    @pushItem="(data) => minhaArea.experienciasProfissional.push(data)"
    />
    <modal-data-videocv v-else-if="dataVideoCVInsertToggle"
    @closemodal="dataVideoCVInsertToggle = false"
    @pushItem="(data) => minhaArea.fullUrlVideoCVUrl = data"
    />
    <app-modal-confirm
      v-if="isModalAssessment"
      @handler-confirm="confirmPurchase"
      @close-modal="isModalAssessment = false"
      :title="`Deseja Adquirir esta entrevista, ${idAssessment.nome}?`"
    />
    <app-loading v-if="loadingAssessment"/>
    <header class="wrapper-header">
      <div class="toobar-wrapper">
        <toobar-search
          class="toolbar-candidates"
          toobar-background="#342041"
          hide-user="flex"
          hide-user-bg="#342041"
          hide-home="flex"
          hide-home-bg="#4B335A"
          input-background="#6A4C7C"
          placeholder-input=""
          hide-input-search="none"
          :hide-user-icon="require('@/assets/img/logout-icon.png')"
        />
      </div>
    </header>
    <div class="tittle-wrapper">
      <h1 class="title-main">Minha área</h1>
    </div>
    <div class="container">
      <app-profile-profissional
      :class="{'sidebar-container-expand': SidebarContainerExpand}"
      :minhaArea="minhaArea"
      :hideData="hideData"
      @handler-toggleEditCV="dataVideoCVInsertToggle = !dataVideoCVInsertToggle"
      @handler-toggleDataPessoal="toggleDataPessoal"
      />
      <div :class="{'hide-evaluation-and-formation-wrapper': EvaluationAndFormationWrapper}" class="evaluation-and-formation-wrapper">
         <section class="evaluation-container">
           <app-card-info class="panel-card" :nome="minhaArea.nome" v-if="minhaArea.idEstado === 1">
            <p>O teu perfil encontra-se a aguardar validação pela RISE WORK.</p>
            <p>Se precisares de ajuda contacta-nos através do e-email</p>
            <p><a href="mailto: hi@work.rise.ao" target="_blank">hi@work.rise.ao.</a></p>
            <p>Até já!</p>
              <p>A RISE WORK</p>
           </app-card-info>
           <app-card-info class="panel-card" :nome="minhaArea.nome" v-if="minhaArea.idEstado === 2">
            <p>O teu perfil encontra-se a aguardar validação pela RISE WORK.</p>
            <p>Se precisares de ajuda contacta-nos através do e-email</p>
            <p><a href="mailto: hi@work.rise.ao" target="_blank">hi@work.rise.ao.</a></p>
            <p>Até já!</p>
              <p>A RISE WORK</p>
           </app-card-info>
           <app-card-info class="panel-card" :nome="minhaArea.nome" v-else-if="minhaArea.idEstado === 3">
            <p>As alterações ao teu perfil encontram-se a aguardar aprovação pela RISE WORK.</p>
            <p>Se precisares de ajuda contacta-nos através do e-email</p>
            <a href="mailto: hi@work.rise.ao" target="_blank">hi@work.rise.ao.</a>
            <p>Até já!</p>
            <p>A RISE WORK</p>
           </app-card-info>
           <app-card-info class="panel-card" :nome="minhaArea.nome" v-else-if="minhaArea.idEstado === 4">
           <p>O teu perfil encontra-se validado.</p>
           <p>Agora deves completar com sucesso o curso Profissional + para passares à próxima etapa.
             Para mais informações, consulta o teu e-email.</p>
           <p>Se precisares de ajuda contacta-nos através do e-email</p>
           <p><a href="mailto: hi@work.rise.ao." target="_blank">hi@work.rise.ao.</a></p>
           <p>Boa sorte!</p>
           <p>A RISE WORK</p>
           </app-card-info>
           <app-card-info class="panel-card" :nome="minhaArea.nome" v-else-if="minhaArea.idEstado === 5">
             <p>Concluíste todos os passos com sucesso. A partir de agora,
               o teu perfil está disponivel na plataforma Rise Work. Boa Sorte!</p>
           </app-card-info>
           <app-card-info class="panel-card" :nome="minhaArea.nome" v-else-if="minhaArea.idEstado === 14">
              <p>Estás a um passo de te tornares um Profissional. Para isso, gostaríamos de ter a oportunidade de te conhecer melhor,
               através de uma entrevista onde avaliaremos o teu perfil e as tuas principais competências.</p>
              <p>Escolhe uma das seguinte opções:</p>
              <div class="assesstment">
                <ul class="list-assesstment">
                  <li v-for="(item, index) in products" :key="index">
                    <app-card-assesstment :product="item" @handler-purchase="purchase" :disabledButton="isDisabled"/>
                  </li>
                </ul>
              </div>

           </app-card-info>
           <app-card-info class="panel-card" :nome="minhaArea.nome" v-else-if="minhaArea.idEstado === 15">
             <p>Aguardamos o pagamento para agendarmos a tua entrevista Top Riser personalizada.</p>
            <div class="reference-payment panel-card">
              <app-card-reference-payment :reference-payment="referencePayment" />
            </div>
           </app-card-info>
           <app-card-info class="panel-card" :nome="minhaArea.nome" v-else-if="minhaArea.idEstado === 16">
             <p>O teu pedido foi submetido. A RISE WORK irá entrar em contacto contigo para o agendamento da tua entrevista profissional e especializada.</p>
           </app-card-info>
          <div v-if="minhaArea.idEstado === 5 && minhaArea.avaliacaoProfissional && minhaArea.avaliacaoProfissional.avaliacaoPontosFortes.length"  class="evaluation-wrapper panel-card">
            <div class="title-container">
              <p>Avaliação</p>
            </div>
            <div class="points-container">
              <app-description-points
              title="Pontos Fortes"
              :items="minhaArea.avaliacaoProfissional.avaliacaoPontosFortes"
              class="strong" />
              <app-description-points
              title="Pontos de Melhoria"
              :items="minhaArea.avaliacaoProfissional.avaliacaoPontosPorMelhorar"
              class="improvement" />
            </div>
          </div>
          <div class="auto-evaluation-wrapper panel-card">
            <br>
            <div class="title-container">
              <p>Auto Avaliação</p>
            </div>
            <br>
            <div class="points-container">
              <app-description-points title="Pontos Fortes" :items="minhaArea.pontosFortes" class="strong" />
              <app-description-points title="Pontos de Melhoria" :items="minhaArea.pontosMelhorar" class="improvement" />
            </div>
          </div>
        </section>
        <section class="formation-container">
          <div class="education-wrapper">
            <p class="main-title">EDUCAÇÃO</p>
            <p v-if="minhaArea.educacoes && minhaArea.educacoes.length" @click="EducationToggle" class="close">{{OpenCloseEd}}</p>
            <div v-for="(item, index) in minhaArea.educacoes" :key="index" class="wrapper">
              <div class="row-sub-title">
                 <img v-show="!edToggle"
                  class="btn-delete"
                  src="@/assets/img/bin.svg"
                  alt=""
                  srcset=""
                  width="16"
                  @click="() => deleteEducacao(item)"
                />
                <p class="sub-title">{{item.areaEducacao}}</p>
              </div>
              <div :class="{'ed-toggle':edToggle}" class="data-wrapper2">
                <div class="content-1">
                  <p class="title">{{item.designacao}}</p>
                  <p class="title">{{item.instituicaoEnsino}}</p>
                  <p class="title">{{item.grauAcademico}}</p>
                </div>
              </div>
            </div>
            <button v-if="minhaArea.educacoes && minhaArea.educacoes.length < 2" class="btn-add" @click="dataAcademicInsertToggle = true">inserir</button>
          </div>
          <div class="formation-wrapper">
            <p class="main-title">Formação</p>
            <p v-if="minhaArea.formacoes && minhaArea.formacoes.length" @click="FormationToggle" class="close">{{OpenCloseFo}}</p>
            <div v-for="(item, index) in minhaArea.formacoes" :key="index">
              <div class="row-sub-title">
                 <img v-show="!foToggle"
                  class="btn-delete"
                  src="@/assets/img/bin.svg"
                  alt=""
                  srcset=""
                  width="16"
                  @click="() => deleteFormacao(item)"
                />
                <p class="sub-title">{{item.areaFormacao}}</p>
              </div>
              <div :class="{'fo-toggle':foToggle}" class="data-wrapper2">
                <div class="content-1">
                  <p class="title">{{item.curso}} <span v-if="item.duracao">({{item.duracao}})</span></p>
                  <p class="title">{{item.instituicao}}</p>
                </div>
              </div>
            </div>
            <button v-if="minhaArea.formacoes && minhaArea.formacoes.length < 3" class="btn-add" @click="dataFormacaoInsertToggle = true">inserir</button>
          </div>
          <div class="expereincia-wrapper">
            <p class="main-title">Experiência Profissional</p>
            <p  v-if="minhaArea.experienciasProfissional !== null || minhaArea.experienciasProfissional !== []"  @click="ExperienceToggle" class="close">{{OpenCloseEp}}</p>
            <div v-for="(item, index) in minhaArea.experienciasProfissional" :key="index">
              <div class="row-sub-title">
                <img v-show="!exToggle"
                  class="btn-delete"
                  src="@/assets/img/bin.svg"
                  alt=""
                  srcset=""
                  width="16"
                  @click="() => deleteExperiencia(item)"
                />
                <p class="sub-title">{{item.funcao}}</p>
              </div>
              <div :class="{'ex-toggle':exToggle}" class="data-wrapper2">
                <div class="content-1">
                  <p class="title">{{item.empresa}} <span v-if="item.experiencia">({{item.experiencia}})</span></p>
                  <p class="title">{{item.sector}}</p>
                  <p class="title">{{item.descricao}}</p>
                </div>
              </div>
            </div>
            <button v-if="minhaArea.experienciasProfissional.length < 3" class="btn-add" @click="dataExperienciaInsertToggle = true">inserir</button>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToobarSearch from '@/components shared/ToobarSearch/toobar-search'
import { deleteEducationProfissional, deleteFormacaoProfissional, deleteExperienciaProfissional } from '@/api/services/register'

export default {
  name: 'MyAreaMainComponent',
  computed: {
    ...mapGetters('minha_area', ['MinhaAreaInfo'])
  },
  mounted () {
    this.getInfo()
    const ScreenSize = window.screen.width
    if (ScreenSize < 768) {
      this.hideData = true
    } else {
      this.showAll = true
      this.SidebarContainerExpand = false
      this.hideData = false
    }
  },
  components: {
    'toobar-search': ToobarSearch,
    'modal-data-academic': require('@/components/Form/modal/modal-data-academic').default,
    'modal-data-formacao': require('@/components/Form/modal/modal-data-formacao').default,
    'modal-data-experiencia': require('@/components/Form/modal/modal-data-experiencia').default,
    'modal-data-videocv': require('@/components/Form/modal/modal-data-videoCV').default,
    'form-loading': require('@/components/Form/loading').default,
    'app-profile-profissional': require('@/components/MyArea/app-profile-profissional').default,
    'app-card-info': require('@/components/MyArea/app-card-info').default,
    'app-description-points': require('@/components/MyArea/app-description-points').default,
    'app-card-reference-payment': require('@/components/MyArea/app-card-reference-payment').default,
    'app-card-assesstment': require('@/components/MyArea/app-card-assesstment').default,
    'app-modal-confirm': require('@/components shared/Modal/app-modal-yes-no.vue').default,
    'app-loading': require('@/components/Form/loading').default
  },
  data () {
    return {
      isDisabled: false,
      minhaArea: {
        educacoes: [],
        experienciasProfissional: [],
        avaliacaoProfissional: {
          avaliacaoPontosFortes: []
        }
      },
      products: [],
      referencePayment: {},
      isModalAssessment: false,
      loadingAssessment: false,
      edToggle: true,
      foToggle: true,
      exToggle: true,
      hideData: true,
      showAll: false,
      formartDate: null,
      OpenCloseFo: 'Mostrar',
      OpenCloseEd: 'Mostrar',
      OpenCloseEp: 'Mostrar',
      SidebarContainerExpand: false,
      dataAcademicInsertToggle: false,
      dataFormacaoInsertToggle: false,
      dataExperienciaInsertToggle: false,
      dataVideoCVInsertToggle: false,
      EvaluationAndFormationWrapper: false,
      loading: false,
      loadingTitle: ''
    }
  },
  created () {
    document.title = 'Rise | Minha Área'
  },
  methods: {
    ...mapActions('minha_area', ['getMinhaAreaInfo', 'getProducts', 'purchaseProduct', 'getReferencePayment']),
    purchase (id) {
      this.idAssessment = id
      this.isModalAssessment = true
    },
    confirmPurchase (state) {
      if (state) {
        this.loadingAssessment = true
        this.isDisabled = true
        this.purchaseProduct(this.idAssessment.id).then(() => {
          this.getInfo()
        }).finally(() => {
          this.loadingAssessment = false
        })
      }
      let time = setTimeout(() => {
        this.isModalAssessment = false
        clearTimeout(time)
      }, 100)
    },
    getInfo () {
      return this.getMinhaAreaInfo('profissionais/minhaArea').then(res => {
        this.minhaArea = res.object
        return res.object
      }).then(data => {
        if (data.idEstado === 14) {
          this.getProducts().then(res => (this.products = res))
        } else if (data.idEstado === 15) {
          this.getReferencePayment().then(res => (this.referencePayment = res))
        }
      })
    },
    EducationToggle () {
      this.edToggle = !this.edToggle
      if (this.edToggle === false) {
        this.foToggle = true
        this.exToggle = true
        this.OpenCloseEd = 'Fechar'
        this.OpenCloseFo = 'Mostrar'
        this.OpenCloseEp = 'Mostrar'
      } else {
        this.OpenCloseEd = 'Mostrar'
      }
    },
    toggleDataPessoal () {
      this.SidebarContainerExpand = !this.SidebarContainerExpand
      this.hideData = !this.hideData
      this.showAll = !this.showAll
      this.EvaluationAndFormationWrapper = !this.EvaluationAndFormationWrapper
    },
    deleteEducacao (item) {
      const ok = window.confirm(`Deseja eliminar esse registo?
      OBS:
      Área de educação: ${item.areaEducacao}
      Designação: ${item.designacao}
      Grau Academico: ${item.grauAcademico}`)
      if (ok) {
        this.loading = true
        deleteEducationProfissional(item.id).then(res => {
          this.loadingTitle = res.data.message
          this.minhaArea.educacoes = this.minhaArea.educacoes.filter(ed => ed.id !== item.id)
        }).finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 3500)
        })
      }
    },
    deleteFormacao (item) {
      const ok = window.confirm(`Deseja eliminar esse registo?
      OBS:
      Área de Estudo: ${item.areaFormacao}
      Curso: ${item.curso}`)
      if (ok) {
        this.loading = true
        deleteFormacaoProfissional(item.id).then(res => {
          this.loadingTitle = res.data.message
          this.minhaArea.formacoes = this.minhaArea.formacoes.filter(ed => ed.id !== item.id)
        }).catch(err => {
          if (err.response) {
            this.loadingTitle = err.response.data.message
          } else {
            this.loadingTitle = err.message
          }
        }).finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 3500)
        })
      }
    },
    deleteExperiencia (item) {
      const ok = window.confirm(`Deseja eliminar esse registo?
      OBS:
      Empresa: ${item.empresa}
      Função: ${item.funcao}`)
      if (ok) {
        this.loading = true
        deleteExperienciaProfissional(item.id).then(res => {
          this.loadingTitle = res.data.message
          this.minhaArea.experienciasProfissional = this.minhaArea.experienciasProfissional.filter(ed => ed.id !== item.id)
        }).finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 3500)
        })
      }
    },
    FormationToggle () {
      this.foToggle = !this.foToggle
      if (this.foToggle === false) {
        this.edToggle = true
        this.exToggle = true
        this.OpenCloseFo = 'Fechar'
        this.OpenCloseEd = 'Mostrar'
        this.OpenCloseEp = 'Mostrar'
      } else {
        this.OpenCloseFo = 'Mostrar'
      }
    },
    ExperienceToggle () {
      this.exToggle = !this.exToggle
      if (this.exToggle === false) {
        this.edToggle = true
        this.foToggle = true
        this.OpenCloseEp = 'Fechar'
        this.OpenCloseFo = 'Mostrar'
        this.OpenCloseEd = 'Mostrar'
      } else {
        this.OpenCloseEp = 'Mostrar'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.show-all{
  display: none;
}
.hide-evaluation-and-formation-wrapper{
  display: none!important;
}
.row-sub-title {
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
    margin-right: 12px;
    &:hover {
      opacity: 0.4;
    }
  }
}
  main{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
   .wrapper-header{
    width: 100%;
    height: 350px;
    background: #FDB00B;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media(max-width:768px){
      height: 100px;
    }
    h1{
      margin-top: 50px;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
    }
    .toobar-wrapper{
      width: 100%;
      padding: 0 10%;
      position: absolute;
      bottom: 0;
      z-index: 999!important;
      @media(max-width:768px){
        padding: 0;
        display: none;
      }
    }
  }
  .btn-add{
    margin-top: 10px;
    width: 80px;
    background: transparent;
    color: #342041;
    padding: 3px 10px;
    border: 1px solid #342041;
    text-transform: capitalize;
    font-size: 14px;
    border-radius:32px;
    cursor: pointer;
    &:hover{
      background: #342041;
      color: #fff;
    }
  }
  .wrapper{
    width: 100%;
  }
  .container{
    width: 100%;
    height: auto;
    display: flex;
    padding: 0;
    max-width: 1300px;
    @media(max-width:786px){
      flex-direction: column;
    }
  }
  .tittle-wrapper{
    display: flex;
    width: 100%;
    max-width: 1303px;
    margin: 0 auto;
    @media(max-width:768px){
      background: #FDB00B;
      justify-content: center;
      padding: 10px 0;
    }
  }
  .title-main{
    text-align: left;
    font-size:  40px;
    padding: 30px  0;
    font-family: "NexaBold";
    text-transform: uppercase;
  }
  .evaluation-and-formation-wrapper{
    width: 980px;
    display: flex;
    justify-content: space-between;
    p {
      margin: .7em 0;
    }
    @media(max-width:768px){
      width: 100%;
      flex-direction: column;
    }
  }
  .evaluation-container{
    width: 550px;
    margin: 0 20px;
    min-height: 100vh;
    height: auto;
    padding:0;
    @media(max-width:768px){
      margin: 0;
      padding: 0;
      width: 100%;
      min-height: auto;
      display: flex;
      flex-direction: column;
    }
  }
  .evaluation-wrapper{
    width: 100%;
    min-height: 200px;
    height: auto;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    justify-content: center;
    @media(max-width:768px){
      padding: 5%;
    }
  }
  .auto-evaluation-wrapper{
    @media(max-width: 768px){
      width: 100%;
      padding: 5%;
    }
  }
  .suggested-wrapper{
    @media(max-width:768px){
      min-height: 200px;
      height: auto;
      padding-left: 5%;
      display: flex;
      width: 100%;
      flex-direction: column;
       .courses-container{
        overflow-x: scroll;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
  .purchased-wrapper{
    width: 100%;
    min-height: 400px;
    height: auto;
    display: flex;
    // align-items: center;
    flex-direction: column;
    justify-content: center;
    @media(max-width:768px){
      padding-left: 5%;
      min-height: 200px;
      .courses-container{
        overflow-x: scroll;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
  .title-container{
    width: 100%;
    display: flex;
    margin: 10px 0;
    justify-content: flex-start;
    p{
      font-size: 30px;
      text-transform: uppercase;
      color: #342041;
    }
  }
  .courses-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .course-wrapper{
    width: 50%;
    display: flex;
    margin: 15px 0;
    justify-content: flex-start;
    @media(max-width: 768px){
      width: auto!important;
      margin-right: 20px;
    }
  }
  .photo-course{
    width: 78px;
    height: 78px;
    border-radius: 5px;
    background: #4B335A;
    margin-right: 15px;
    box-shadow: 1px 1px 12px #0000005C;
    @media(max-width:768px){
      width: 108px;
      height: 108px;
    }
  }
  .data-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title {
    text-align: left;
    text-transform:capitalize!important;
    margin: 0;
    font-size: 14px;
    &:empty {
      display: none;
    }
  }
  .price-wrapper2{
    background: #4B335A!important;
  }
 .price-wrapper3{
    background: #26A0A5!important;
  }
  .price-wrapper2,
  .price-wrapper3,
  .price-wrapper{
    display: flex;
    background: #FDB00B;
    border-radius: 10px;
    align-items: center;
    padding: 2px 5px;
    margin-right: 4px;
    p{
      font-size: 10px;
      color: #fff;
    }
  }
  .label-wrapper{
    margin: 5px 0;
    display: flex;
    align-items: center;
  }
  .state{
    font-size: 14px;
  }
  .points-container{
    display: flex;
    height: auto;
    @media(max-width: 768px){
      width: 100%;
      justify-content: center;
      flex-direction: row;
    }
  }
  .formation-container{
    width: 400px;
    background: #F5F5F5;
    height: auto;
    min-height: 70vh;
    @media(max-width: 768px){
      height: auto!important;
      min-height: unset;
    }
  }
  .formation-wrapper{
    background: #EAEAEA!important;
  }
  .expereincia-wrapper{
    background: #F5F5F5!important;
  }
  .formation-wrapper,
  .expereincia-wrapper,
  .education-wrapper{
    display: flex;
    padding: 20px 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    background:#F5F5F5;
  }
  .main-title{
    text-transform: uppercase;
    font-size: 30px;
    color: #4B335A;
    letter-spacing: 3.2px;
    text-align: start;
  }
  .sub-title{
    font-size: 16px;
    color: #4B335A;
    text-align: start;
    margin: 10px 0;
  }
  .close{
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: #26A0A5;
    right: 30px;
    @media(max-width:768px){
      right: 50px;
    }
     @media(max-width:338px){
      right: 90px;
    }
  }
  .edit{
    position: absolute;
    right: 0;
    top: 80px;
    cursor: pointer;
    font-size: 14px;
    color: #505050;
    font-weight: bold;
    right: 30px;
  }
  .data-wrapper2{
    display: flex;
    width: 100%!important;
    .content-2,
    .content-1{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      .title-active{
        font-weight: bolder;
        color: #9D9D9D;
        padding: 2px 0;
        text-transform:capitalize;
        text-align:start
      }
      .title{
        padding: 2px 0;
        color: #9D9D9D;
        @media(max-width:768px){
          padding-right: 10px;
          text-align: start;
        }
      }
    }
  }
  .ed-toggle,
  .fo-toggle,
  .ex-toggle{
    display: none;
    transition: display 0.4s ;
  }
  // panel-card styles
  .panel-card {
    margin: 0;
    padding: 20px 30px;
    &> p {
      color: #4b335a;
    }
    &:first-child {
      margin-top: 0;
    }
    &:nth-child(2n) {
      background: #F5F5F5;
    }
    &:nth-child(2n + 1) {
      background: #EDEDED;
    }
  }
  // styles assesstment
  .assesstment {
    & > p {
      font-size: 16px;
    }
  }
  .list-assesstment {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-evenly;
    li {
      margin: 40px 0;
      align-self: stretch;
    }
  }
  // reference payment
  .reference-payment {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
  }
  // await payment
  .await-payment {
    text-align: center;
    color: #4B335A;
    font-size: 20px;
  }
</style>
