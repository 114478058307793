<template>
  <div>
    <my-area-main-component/>
  </div>
</template>

<script>
import MyAreaMainComponent from '@/components/MyArea/Profissional'
export default {
  name: 'MyArea',
  components: {
    'my-area-main-component': MyAreaMainComponent
  }
}
</script>

<style lang="scss" scoped>
  main{
    height: auto;
    width: 100%;
    min-height: 100vh;
  }
</style>
