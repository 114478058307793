<template>
  <div class="card-assesstment">
    <header class="assesstment-title">
      <strong>{{ product.nome }}</strong>
    </header>
    <p v-html="product.descricao"></p>
    <button :disabled="disabledButton" class="submit-assesstment" @click="$emit('handler-purchase', product)">
      {{ product.preco === 0 ? "Grátis" : product.precoF }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'app-card-assesstment',
  props: {
    product: Object,
    disabledButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.card-assesstment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #4B335A;
  border: 1px solid #4B335A;
  border-radius: 20px;
  padding: 10px;
  width: 200px;
  height: 100%;
  background: #F5F5F5;
  position: relative;
  header, button {
    margin: 4px 0;
  }
  header {
    width: 100%;
  }
  p {
    width: 100%;
    min-height: 100px;
    font-size: 14px;
    margin: 18px 0;
    line-height: 20px;
    margin-bottom: 60px;

  }
  button {
    border-radius: 12px;
    padding: 6px 12px;
    border-style: none;
    color: #4B335A;
    background-color: #FDB00B;
    min-width: 100px;
    cursor: pointer;
    bottom: 10px;
    position: absolute;
    transition: opacity 300ms 0s ease-out;
    &:hover {
      opacity: 0.8;
      color: #000;
    }
  }
}
</style>
