<template>
  <section class="sidebar-container">
          <div class="photo-container">
            <user-avatar
            class="user-big"
            :avatar="minhaArea.avatarUrl"
            :tipoRise="minhaArea.tipoRiseImagem"
            :idTipoRiser="minhaArea.idTipoRiser"
            />
          </div>
          <h1 class="name">{{minhaArea.nome}} {{minhaArea.apelido}}</h1>
          <br>
          <div :class="{'hide-data': hideData}">
            <p class="details-white">{{minhaArea.sexoDesc}}, {{minhaArea.idade}} anos</p>
            <br>
            <p class="details-title">DATA DE NASCIMENTO</p>
            <p class="details-white">{{minhaArea.dataNascimentoF}}</p>
            <p class="details-title">HABILITAÇÃO</p>
            <p class="details-white">{{minhaArea.habilitacao}}</p>
            <p class="details-title">Nacionalidade</p>
            <p class="details-white">{{minhaArea.nacionalidade}}</p>
            <br>
            <p class="details-title">Nº de documento</p>
            <p class="details-white">{{minhaArea.numDoc}}</p>
            <p class="details-title">Cidade</p>
            <p class="details-white">{{minhaArea.cidade}}</p>
            <p class="details-title">Cidades Disponíveis</p>
            <div v-for="cidade in minhaArea.cidadesDisponivel" :key="cidade.id">
              <p class="details-white">{{cidade.nome}}</p>
            </div>
            <p class="details-title">Idiomas</p>
            <div v-for="item in minhaArea.idiomas" :key="item.id">
              <p class="details-white">{{item.idioma}}</p>
            </div>
            <div class="edit-container">
            </div>
            <span class="edit-cv" @click="$emit('handler-toggleEditCV')">ALTERAR O VÍDEO CV</span>
            <div class="upload-container">
              <button @click="seeVC" class="input-file" type="button">
                O MEU VÍDEO CV
              </button>
            </div>
          </div>
          <button @click="$emit('handler-toggleDataPessoal')" class="toggle-data-pessoal">{{!hideData ? 'Fechar' : 'Mostrar'}}</button>
      </section>
</template>

<script>
export default {
  name: 'app-profile-profissional',
  props: {
    minhaArea: Object,
    hideData: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'user-avatar': require('@/components shared/user-avatar').default
  },
  data () {
    return {
    }
  },
  methods: {
    seeVC () {
      window.open(`${this.minhaArea.fullUrlVideoCVUrl}`)
    },
    toggleDataPessoal () {
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-container-expand{
    @media(max-width:768px){
    min-height: 100vh!important;
  }
}
.sidebar-container{
  min-height: 100vh;
  height: auto;
  display: flex;
  width: 300px;
  padding: 0 30px 30px 30px;
  background: #342041;
  flex-direction: column;
  position: relative;
  @media(max-width:768px){
    width: 100%;
    min-height: 62vh;
  }
  .hide-data{
    display: none;
  }
  .photo-container{
    width: 100%;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media(max-width:768px){
      margin: 40px 0;
    }
  }
  .photo-wrapper{
    background: #FDB00B;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: relative;
  }
  .rise-top{
    width: 70px;
    right: 40px;
    top: 150px;
    position: absolute;
    width: 50px;
    @media(max-width:768px){
      width: 70px;
      top: 140px;
      right: 46px;
    }
  }
  .circle-image::after{
    content: '';
    display: block;
    margin: 25px 0 0 15px;
    border-radius: 50%;
    width: 124px;
    height: 124px;
    background: #FDB00B 0% 0% no-repeat padding-box;
    opacity: 0.72;
    filter: blur(15px);
    @media(max-width:768px){
      width: 166px!important;
      height: 166px!important;
      margin: 20px 0 0 12px!important;
    }
  }
  .circle-image{
    width: 148px;
    height: 148px;
    background: #FDB00B;
    border-radius: 50%;
    margin-bottom: 13px;
    @media(max-width:768px){
      margin-bottom: 26px;
      width: 189px!important;
      height: 189px!important;
    }
  }
  .wrapper-picture{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    @media(max-width:768px){
      width: 110px!important;
      height: 110px!important;
      margin-bottom: 25px!important;
      img{
        width: 110px!important;
        height: 110px!important;
      }
    }
    img{
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .toggle-data-pessoal{
    bottom: 0px;
    margin-top: 20px;
    position: relative;
    text-align: right;
    @media(min-width:768px){
      display: none;
    }
  }
  .toggle-data-pessoal,
  .btn-show-all,
  .text-edit{
    right: 0;
    bottom: -20px;
    color: #26A0A5;
    letter-spacing: 1.2px;
    cursor: pointer;
    border: none;
    font-weight: bolder;
    background: transparent;
    text-transform: uppercase;
  }
  .edit-container{
    width: 100%;
    height: 50px;
    position: relative;
  }
  .name{
    font-size: 40px;
    text-transform:capitalize;
    color: #FDB00B;
    font-weight: bolder;
    text-align: start;
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
  .details-title{
    color: #9D9D9D;
    font-size: 14px;
    text-align: start;
    text-transform: uppercase;
    margin: 5px 0;
    @media(max-width:768px){
      font-size: 18px;
    }
  }
  .details-white{
    color: #fff;
    font-size: 14px;
    text-align: start;
    margin: 5px 0;
    @media(max-width:768px){
      font-size: 18px;
    }
  }
  .upload-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .edit-cv {
    color: #26A0A5;
    cursor: pointer;
    margin-top: 5px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.8em;
    &:hover {
      color: #03575a;
    }
  }
  .input-file {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-top: 5px;
    height: 60px;
    border: none;
    background-color: #FDB00B;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    transition: background-color .3s ease;
    color: #fff;
    font-size: 14px;
    font-family: 'arial';
    text-align: center;
  }
  .input-file:hover {
    background-color: #FDB00B90;
  }
}
</style>
