<template>
  <div class="text-wrapper-state">
    <p>
      Olá <span class="name">{{ nome }}</span>!
    </p>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'app-card-info',
  props: {
    nome: String
  }
}
</script>
<style lang="scss" scoped>
.text-wrapper-state{
  width: 100%;
  height: auto;
  padding: 20px 30px;
  font-size: 16px;
  background: #EDEDED;
  @media(max-width: 768px){
      margin-top: 0px;
      padding:0 15px 30px  15px;
  }
  p{
    text-align: left;
    color: #4b335a;
  }
}
</style>
