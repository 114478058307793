<template>
  <div class="courses-modal">
    <transition name="modal">
      <div class="modal-container">
        <div class="modal-wrapper">
          <button class="modal-btn-close" @click="$emit('close-modal')">X</button>
          <h3 class="modal-title">{{title}}</h3>
          <div class="controls">
            <button @click="$emit('handler-confirm', false)" class="btn-yes-ok">Não</button>
            <button @click="$emit('handler-confirm', true)" class="btn-yes-ok">Sim</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data () {
    return {
      ShowOklBtn: false,
      ShowYeslBtn: true
    }
  },
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  .courses-modal{
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .modal-container {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #34204122 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    transition: opacity 0.3s ease;
    @media(max-width:768px){
      backdrop-filter: blur(11px);
      -webkit-backdrop-filter: blur(11px);
    }
  }
  .moadal-title{
    max-width: 250px;
  }
  .btn-yes-ok{
    border: none;
    padding: 10px;
    width: 250px;
    margin-top: 30px;
    background-color: #342041;
    cursor: pointer;
    border-radius: 6px;
    color: #fff;
    &:last-child {
      background-color: #f0a608;
    }
    &:hover{
      background-color: #551948;
    }
  }
  .modal-wrapper {
    max-width:1131px ;
    width: 400px;
    max-height: 704px;
    height: 400px;
    background: #f2f2f2dc;
    display: flex;
    box-shadow: 6px 6px 15px #00000015;
    border-radius: 22px;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    @media(max-width:768px){
      margin-top: 10vh;
      width: 80%;
      height: 80vh;
    }
    .controls {
      display: flex;
      margin-top: 50px;
      button {
        width: 100px;
        margin: 0 20px;
      }
    }
    .modal-btn-close{
      position: absolute;
      top: 15px;
      right: 15px;
      width: 43px;
      height: 43px;
      font-weight: bold;
      font-size: 16px;
      z-index: 777;
      -webkit-box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
      box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
      border: none;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
      @media(max-width:768px){
        top: -60px;
        right:0px;
      }
    }
  }
</style>
