<template>
  <modal-form @closemodal="$emit('closemodal')" @add-register="addExperiencia">
    <div>
    <form-loading v-show="loading" :title="loadingTitle"/>
      <header-title title="Experiência Profissional" />
      <div class="section-container">
        <section class="section">
            <div class="input-wrapper">
              <input-form
                title="Empresa"
                :error="errors.empresa[Object.keys(errors.empresa)[0]] ? Object.keys(errors.empresa)[0] : ''"
                class="add-margin"
                dataType="text"
                placeholder=""
                v-model="empresa"
                @focus="resetWidget"
              />
            </div>
            <div class="input-wrapper">
              <input-datalist
                title="Sector"
                :error="errors.sector[Object.keys(errors.sector)[0]] ? Object.keys(errors.sector)[0] : ''"
                field="w_isSector"
                :isShow="w_isSector"
                @setData="(value) => setData('sector', value)"
                placeholder=""
                :value="sector.nome"
                class="item"
                :dataSource="ListasFormulario.sectores"
                :themeStyle="theme"
                v-on:w_isSector="toggleWidget"
              />
            </div>
            <div class="input-wrapper">
            <input-datalist
                title="Função"
                :error="errors.funcao[Object.keys(errors.funcao)[0]] ? Object.keys(errors.funcao)[0] : ''"
                field="w_isFuncao"
                :isShow="w_isFuncao"
                @setData="(value) => setData('funcao', value)"
                placeholder=""
                :value="funcao.nome"
                class="item"
                :dataSource="ListasFormulario.funcoes"
                :themeStyle="theme"
                v-on:w_isFuncao="toggleWidget"
              />
            </div>
        </section>
        <section class="section section-right">
            <div class="input-wrapper">
              <input-datalist
                title="Experiência"
                :error="errors.experiencia[Object.keys(errors.experiencia)[0]] ? Object.keys(errors.experiencia)[0] : ''"
                field="w_isExperiencia"
                fieldName="designacao"
                :isShow="w_isExperiencia"
                @setData="(value) => setData('experiencia', value)"
                placeholder=""
                :value="experiencia.designacao"
                class="item"
                :dataSource="ListasFormulario.experiencias"
                :themeStyle="theme"
                v-on:w_isExperiencia="toggleWidget"
              />
            </div>
            <div class="input-wrapper">
              <p class="label-wrappper-title2">Descrição </p>
              <span
              :style="{color: '#f00', fontSize: '12px', display: 'block', textAlign: 'left'}">
                {{
                  errors.mensagem[Object.keys(errors.mensagem)[0]] ?
                  Object.keys(errors.mensagem)[0] : ''
                }}
              </span>
              <textarea
                placeholder="minha descrição"
                type="text"
                name=""
                v-model="mensagem"
                class="textarea-description"
                @focus="resetWidget"
              />

            </div>
        </section>
      </div>
    </div>
  </modal-form>
</template>

<script>
import { getRegisterList, insertExperienciaProfissional } from '@/api/services/register'
export default {
  name: 'form-data-submission',
  components: {
    'input-datalist': require('@/components/Form/input-datalist').default,
    'input-form': require('@/components/Form/input-form').default,
    'form-loading': require('@/components/Form/loading').default,
    'modal-form': require('@/components/Form/modal/modal-form').default

  },
  props: {
    form: Object,
    theme: Object
  },
  data () {
    return {
      w_isSector: false,
      w_isFuncao: false,
      w_isExperiencia: false,
      ListasFormulario: {
        sectores: [],
        funcoes: [],
        experiencias: []
      },
      sector: '',
      funcao: '',
      empresa: '',
      mensagem: '',
      loading: false,
      experiencia: '',
      loadingTitle: '',
      errors: {
        empresa: {
          'Empresa inválida': false
        },
        sector: {
          'Sector inválido': false
        },
        funcao: {
          'Função inválida': false
        },
        experiencia: {
          'Experiência inválida': false
        },
        mensagem: {
          'Mensagem requerida': false
        }
      }
    }
  },
  methods: {
    setData (field, value) {
      this[field] = value
      this.resetWidget()
    },
    valideField (field) {
      const key = Object.keys(this.errors[field])[0]
      this.errors[field][key] = false
    },
    toggleWidget (property, value) {
      this.resetWidget()
      this[property] = !value
    },
    resetWidget () {
      this.w_isSector = false
      this.w_isFuncao = false
      this.w_isExperiencia = false
    },
    addExperiencia () {
      const { empresa, mensagem, sector, funcao, experiencia, errors } = this

      let ok = true
      Object.keys(this.errors).forEach(field => this.valideField(field))

      if (sector === '') {
        const property = Object.keys(errors.sector)[0]
        this.errors.sector[property] = true
        ok = false
      }
      if (funcao === '') {
        const property = Object.keys(errors.funcao)[0]
        this.errors.funcao[property] = true
        ok = false
      }
      if (experiencia === '') {
        const property = Object.keys(errors.experiencia)[0]
        this.errors.experiencia[property] = true
        ok = false
      }
      if (mensagem === '') {
        const property = Object.keys(errors.mensagem)[0]
        this.errors.mensagem[property] = true
        ok = false
      }
      if (empresa === '') {
        const property = Object.keys(errors.empresa)[0]
        this.errors.empresa[property] = true
        ok = false
      }
      if (!ok) {
        return
      }
      this.loading = true
      insertExperienciaProfissional({
        empresa,
        descricao: mensagem,
        idSector: sector.id,
        idFuncao: funcao.id,
        idExperiencia: experiencia.id
      }).then(res => {
        this.loadingTitle = res.data.message
        this.$emit('pushItem', res.data.object)
        setTimeout(() => {
          this.$emit('closemodal')
          this.tipo = ''
          this.area = ''
          this.curso = ''
          this.duration = ''
          this.instituicao = ''
        }, 2000)
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 3000)
      })
      this.idExperiencia = ''
      this.empresa = ''
      this.mensagem = ''
      this.sector = ''
      this.funcao = ''
      this.duracao = ''
    }
  },
  mounted () {
    getRegisterList().then(res => {
      this.ListasFormulario.experiencias = res.data.object.experiencias
      this.ListasFormulario.sectores = res.data.object.sectores
      this.ListasFormulario.funcoes = res.data.object.funcoes
    })
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss" scoped>
.mb {
  margin-bottom: 160px;
}
.textarea-description {
  padding: 20px !important;
  height: 175px !important;
  width: 100%;
  border: 2px solid #8F789C;
  margin: 10px 0;
  color: #4b335a;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
  &::placeholder {
    color: #eee;
    font-size: 20px;
  }
}

.form-container {
  margin-bottom: 50px;
  &.width-middle {
    width: 50%;
    .input-container {
      flex: 0.8;
    }
  }
}

.section-container {
  display: flex;
  justify-content: center;
  width: 100%;
  &.flex-start {
    justify-content: flex-start;
  }
  .section {
    flex: 0.8;
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .input-wrapper, .input-row {
    width: 88%;
  }
  .input-wrapper {
    margin-top: 6px;
  }
  .input-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .input-wrapper {
      flex: 0.45;
      &.flex-1 {
        flex: 0.3;
      }
      &.flex-2 {
        flex: 0.65
      }
    }
  }
}

.label-wrappper-title2 {
  font-size: 18px;
  color: #4b335a;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
@media only screen and (max-width: 768px) {
  .margin-left {
    margin-left: 25px;
  }
  .form-container .width-middle {
    width: 90%;
  }
  .section-container {
    flex-direction: column;
    align-items: center;
    .input-row {
      flex-direction: column;
      align-items: flex-start;
    }
    .input-wrapper, .input-row {
      width: 98%;
    }
    .input-wrapper {
      margin: 1rem 0;
    }
    .section {
      width: 88%;
      &:nth-child(2) {
        align-items: flex-start;
      }
    }
  }
}
</style>
