<template>
  <modal-form @closemodal="$emit('closemodal')" @add-register="addVideoCV">
    <div>
      <form-loading v-show="loading" :title="loadingTitle"/>
      <header-title title="Video CV" />
      <div class="section-container">
        <section class="section">
          <div class="input-wrapper">
            <p class="label-wrappper-title2">Vídeo CV
              <span :style="{color: '#f00', fontSize: '12px'}">{{
                errors.videoCVUrlTmp[Object.keys(errors.videoCVUrlTmp)[videoIndexError]] ? '('+Object.keys(errors.videoCVUrlTmp)[videoIndexError]+')' : ''
              }}</span>
              </p>
            <button class="input-file">
              <input type="file" id="file-input" @change="setVideo" />
              <label for="file-input">{{videoCVUrlTmp ? 'ficheiro carregado ' : 'Carregar ficheiro'}} {{videoCVUrlTmp && videoCVUrlTmp.name | nameFile}}</label>
              <div class="progress"></div>
            </button>
          </div>
        </section>
      </div>
    </div>
  </modal-form>
</template>

<script>
import { insertVideoCVProfissional } from '@/api/services/register'
export default {
  name: 'modal-data-videocv',
  props: {
    theme: Object
  },
  components: {
    'form-loading': require('@/components/Form/loading').default,
    'modal-form': require('@/components/Form/modal/modal-form').default
  },
  filters: {
    nameFile (name) {
      return name.length < 40 ? name : name.substr(0, 30) + '...' + name.substr(name.length - 10)
    }
  },
  methods: {
    returnFileSize (number) {
      let value = 0
      let unit = 'bytes'
      if (number < 1024) {
        value = number
        unit = 'bytes'
      } else if (number >= 1024 && number < 1048576) {
        value = (number / 1024).toFixed(1)
        unit = 'KB'
      } else if (number >= 1048576) {
        value = (number / 1048576).toFixed(1)
        unit = 'MB'
      }
      return {
        value: parseFloat(value),
        unit
      }
    },
    setVideo (e) {
      this.videoIndexError = 0
      Object.keys(this.errors.videoCVUrlTmp).forEach(error => {
        this.errors.videoCVUrlTmp[error] = false
      })
      const video = e.target.files[0]
      const size = this.returnFileSize(video.size)
      if (!size) return
      if (size.unit === 'MB' && size.value >= 26) {
        this.videoIndexError = 1
        const property = Object.keys(this.errors.videoCVUrlTmp)[this.videoIndexError]
        this.errors.videoCVUrlTmp[property] = true
        return
      }
      if (e.target.files[0].type.indexOf('video/') === -1) {
        this.videoIndexError = 2
        const property = Object.keys(this.errors.videoCVUrlTmp)[this.videoIndexError]
        this.errors.videoCVUrlTmp[property] = true
        return
      }
      this.videoCVUrlTmp = e.target.files[0]
    },
    valideField (field) {
      const key = Object.keys(this.errors[field])[0]
      this.errors[field][key] = false
    },
    addVideoCV () {
      const { errors, videoCVUrlTmp } = this
      let ok = true
      Object.keys(this.errors).forEach(field => this.valideField(field))
      if (videoCVUrlTmp === '') {
        this.videoIndexError = 0
        const property = Object.keys(errors.videoCVUrlTmp)[this.videoIndexError]
        this.errors.videoCVUrlTmp[property] = true
        ok = false
      }
      if (!ok) {
        return
      }
      // endpoint
      this.loading = true
      this.loadingTitle = 'Actualizando o seu vídeo CV...'
      insertVideoCVProfissional(videoCVUrlTmp).then((res) => {
        this.$emit('pushItem', res.data.object)
        this.loadingTitle = res.data.message
        setTimeout(() => {
          this.$emit('closemodal')
        }, 2000)
      }).catch(err => {
        if (err.response) {
          this.loadingTitle = err.response.data.message
        } else {
          this.loadingTitle = err.message
        }
      }).finally(() => {
        setTimeout(() => {
          this.loadingTitle = ''
          this.loading = false
          this.videoCVUrlTmp = ''
        }, 3000)
      })
      return 1
    },
    setData (field, value) {
      this[field] = value
    }
  },
  data () {
    return {
      videoCVUrlTmp: '',
      loading: false,
      loadingTitle: '',
      videoIndexError: 0,
      errors: {
        videoCVUrlTmp: {
          'Video CV mandatório': false,
          'limite do seu video CV é de 25MB': false,
          'Teu CV deve está no formato de vídeo': false
        }
      },
      error: ''
    }
  }
}
</script>

<style lang="scss" scoped>

.section-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  .section {
    flex: 0.8;
    .input-file {
      position: relative;
      overflow: hidden;
      width: 100%;
      margin-top: 5px;
      height: 60px;
      border: 1px solid #4B335A;
      background-color: #fff;
      border-radius: 3px;
      position: relative;
      cursor: pointer;
      transition: background-color .3s ease;
      .progress {
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: 5px;
        left: 0;
        background-color: #4B335A;
      }
    }
    .input-file:hover {
      background-color: #f2f2f2;
    }
    .input-file [type = file] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    .input-file label {
      font-family: 'arial';
      color: #aaa;
      text-align: left;
      font-size: 14px;
      cursor: pointer;
      left: 20px;
      bottom: 20px;
      position: absolute;
    }
  }
  .input-wrapper {
    width: 88%;
  }
  .input-wrapper {
    margin-top: 6px;
  }
}
.label-wrappper-title2 {
  font-size: 18px;
  color: #4b335a;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
@media only screen and (max-width: 768px) {
  .margin-left {
    margin-left: 25px;
  }
  .section-container {
    flex-direction: column;
    align-items: center;
    .input-wrapper {
      width: 98%;
    }
    .input-wrapper {
      margin: 1rem 0;
    }
    .section {
      width: 88%;
    }
  }
}
</style>
