<template>
<div data-picklist
  data-input
  data-list-show="false"
  :class="[color]"
  tabindex="0"
  @keyup="handlerKeyUp"
  role="listbox"
  class="container-datalist">
  <p class="title">{{title}}</p>
  <div
  class="input-control"
  :class="{ completed: completed || value, [color]: true }"
  >
    <p class="error">{{error}}</p>
    <label @click="toggleWidget" :class="{placeholder: !value}">{{value || placeholder}}</label>
    <widget-datalist v-if="isShow" :class="{[align]: true}" :fieldName="fieldName" :data="dataSource" :value="value" @setData="setData" :themeStyle="themeStyle"/>
    <span @click="toggleWidget" data-input>{{!isShow ? '+' : '-'}}</span>
  </div>
</div>
</template>

<script>
export default {
  name: 'input-datalist',
  components: {
    'widget-datalist': require('@/components/Form/widget-datalist').default
  },
  props: {
    placeholder: String,
    value: [String, Object],
    error: String,
    dataSource: Array,
    themeStyle: Object,
    fieldName: String,
    title: String,
    topAlign: Boolean,
    align: {
      type: String,
      default: window.innerWidth > 768 ? 'top' : 'bottom'
    },
    color: {
      type: String,
      default: 'primary'
    },
    isShow: {
      type: Boolean,
      default: false
    },
    field: [String, Number]
  },
  data () {
    return {
      completed: false
    }
  },
  updated () {
    this.completed = !!this.value
  },
  methods: {
    handlerKeyUp (e) {
      if (e.keyCode === 13) {
        this.toggleWidget()
      }
    },
    toggleWidget () {
      if (this.dataSource.length) {
        this.$emit(this.field, this.field, this.isShow)
      }
      if (!this.isShow) {
        this.completed = false
      }
    },
    setData (value) {
      const timerID = setTimeout(() => {
        this.$emit('setData', value)
        this.completed = true
        clearTimeout(timerID)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.mt .title {
  margin-top: 0;
}
.primary {
  color: #6a4c7cc0 !important;
}
.mt-0, .mt-0 .title {
  margin-top: 0 !important;
}
.title {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
  color: inherit;
  font-size: 18px;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
.top {
  bottom: 60px;
  left: 0;
  top: initial;
}
.bottom {
  bottom: initial;
  left: 0;
  top: 60px;
}
.input-control {
  position: relative;
  background-color: #fff;
  border: 2px solid;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  padding: 1rem;
  height: 60px!important;
  display: flex;
  align-items: center;
  position: relative;
  .error {
    color: #f00;
    font-size: 12px;
    position: absolute;
    left: 5px;
    top: 0;
  }
  &.completed {
    background-color: #50366054;
    label {
      color: #fff;
    }
  }
  &.disabled {
    cursor: not-allowed;
    input, label {
      pointer-events: none;
    }
  }
  label {
    width: 100%;
    flex: 1;
    border-style: none;
    outline-style: none;
    font-size: 16px;
    background: transparent;
    text-align: left;
    color: inherit;
    display: flex;
    align-items: center;
    &.placeholder {
      color: rgba(138, 133, 133, 0.5);
    }
    @media(max-width:560px){
     display: flex;
     align-items: center;
    }
  }
  input[disabled] {
    background-color: transparent;
  }
  label {
    font-weight: 100;
    color: inherit;
    font-family: 'NexaLight';
    @media(max-width:560px){
      font-size: 16px!important;
    }
  }
  label {
    color: inherit;
  }
  span {
    font-size: 1.5rem;
    font-weight: 900;
    color: orange;
    button {
      cursor: pointer;
      font-size: 1.5rem;
      font-weight: 900;
      color: orange;
      background-color: transparent;
      border-style: none;
    }
  }
}
@media screen and (min-width: 768px) {
  .desktop-top {
    bottom: 60px;
    left: 0;
    top: initial;
  }
  .desktop-bottom {
    bottom: initial;
    left: 0;
    top: 60px;
  }
}
</style>
