<template>
  <modal-form @closemodal="$emit('closemodal')" @add-register="insertFormacao">
    <div>
      <form-loading v-show="loading" :title="loadingTitle"/>
      <header-title title="Formação" />
      <div class="form-container section-container">
        <section class="section-left section">
          <div class="input-wrapper">
            <input-form
            title="Curso"
            :error="errors.curso[Object.keys(errors.curso)[0]] ? Object.keys(errors.curso)[0] : ''"
            placeholder=""
            v-model="curso"
            @focus="resetWidget"/>
          </div>
          <div class="input-wrapper">
            <input-form
            title="Instituição"
            :error="errors.instituicao[Object.keys(errors.instituicao)[0]] ? Object.keys(errors.instituicao)[0] : ''"
            placeholder=""
            v-model="instituicao"
            @focus="resetWidget"/>
          </div>
        </section>
        <section class="section-right section">
            <div class="input-wrapper">
              <input-datalist
                title="Duração"
                :error="errors.duracao[Object.keys(errors.duracao)[0]] ? Object.keys(errors.duracao)[0] : ''"
                field="w_isDuration"
                :isShow="w_isDuration"
                @setData="(value) => setData('duration',value)"
                placeholder="3 Meses"
                :value="duration.nome"
                class="item"
                :dataSource="ListasFormulario.duracoes"
                :themeStyle="theme"
                v-on:w_isDuration="toggleWidget"
              />
            </div>
            <div class="input-wrapper">
              <input-datalist
                title="Área de Formação"
                :error="errors.area[Object.keys(errors.area)[0]] ? Object.keys(errors.area)[0] : ''"
                field="w_isArea"
                :isShow="w_isArea"
                @setData="(value) => setData('area', value)"
                placeholder=""
                :value="area.nome"
                class="item"
                :dataSource="ListasFormulario.areasFormacao"
                :themeStyle="theme"
                v-on:w_isArea="toggleWidget"
              />
            </div>
        </section>
      </div>
    </div>
  </modal-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { getRegisterList, insertFormacaoProfissional } from '@/api/services/register'
export default {
  name: 'form-idioma-availability',
  components: {
    'input-datalist': require('@/components/Form/input-datalist').default,
    'input-form': require('@/components/Form/input-form').default,
    'form-loading': require('@/components/Form/loading').default,
    'modal-form': require('@/components/Form/modal/modal-form').default
  },
  props: {
    form: Object,
    theme: Object
  },
  data () {
    return {
      w_isOnde: false,
      w_isTipo: false,
      w_isArea: false,
      w_isCurso: false,
      w_isDuration: false,
      area: '',
      curso: '',
      duration: '',
      formacoes: {},
      instituicao: '',
      ListasFormulario: {
        areasFormacao: [],
        duracoes: []
      },
      errors: {
        curso: {
          'Curso inválido': false
        },
        instituicao: {
          'Instituição inválida': false
        },
        duracao: {
          'Duração inválida': false
        },
        area: {
          'Area de formação inválida': false
        }
      },
      loading: false,
      loadingTitle: ''
    }
  },
  validations: {
    instituicao: {
      required
    },
    curso: {
      required
    }
  },
  mounted () {
    getRegisterList().then(res => {
      this.ListasFormulario.areasFormacao = res.data.object.areasFormacao
      this.ListasFormulario.duracoes = res.data.object.duracoes.map(item => ({ id: item.id, nome: item.designacao }))
    })
  },
  methods: {
    toggleWidget (property, value) {
      this.resetWidget()
      this[property] = !value
    },
    valideField (field) {
      const key = Object.keys(this.errors[field])[0]
      this.errors[field][key] = false
    },
    resetWidget () {
      this.w_isOnde = false
      this.w_isDuration = false
      this.w_isCurso = false
      this.w_isArea = false
      this.w_isTipo = false
    },
    insertFormacao () {
      const { duration, area, $v, errors } = this
      const { curso, instituicao } = $v
      let ok = true
      Object.keys(errors).forEach(field => this.valideField(field))
      if (curso.$model === '' || curso.$error) {
        const property = Object.keys(errors.curso)[0]
        this.errors.curso[property] = true
        ok = false
      }
      if (instituicao.$model === '' || instituicao.$error) {
        const property = Object.keys(errors.instituicao)[0]
        this.errors.instituicao[property] = true
        ok = false
      }
      if (duration === '') {
        const property = Object.keys(errors.duracao)[0]
        this.errors.duracao[property] = true
        ok = false
      }
      if (area === '') {
        const property = Object.keys(errors.area)[0]
        this.errors.area[property] = true
        ok = false
      }

      if (!ok) {
        return
      }
      this.loading = true
      insertFormacaoProfissional({
        curso: curso.$model,
        instituicao: instituicao.$model,
        idDuracao: duration.id,
        idAreaFormacao: area.id
      }).then(res => {
        this.loadingTitle = res.data.message
        this.$emit('pushItem', res.data.object)
        setTimeout(() => {
          this.$emit('closemodal')
          this.tipo = ''
          this.area = ''
          this.curso = ''
          this.duration = ''
          this.instituicao = ''
        }, 2000)
      }).catch(err => {
        if (err.response) {
          this.loadingTitle = err.response.data.message
        } else {
          this.loadingTitle = err.message
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 3000)
      })
    },
    setFields (key, value) {
      this.language.idioma = value
      this.resetWidget()
    },
    setData (field, value) {
      this[field] = value
      this.resetWidget()
    }
  }
}
</script>

<style lang="scss" scoped>

.form-container {
  margin-bottom: 50px;
  &.width-middle {
    width: 50%;
    .input-container {
      flex: 0.8;
    }
  }
}
.section-container {
  display: flex;
  justify-content: center;
  width: 100%;
  .section {
    flex: 0.8;
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .avatar-container {
      width: 180px;
      height: 180px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 3px 10px #00000029;
      padding: 10px;
      position: relative;
      .avatar-title {
        font-size: 10px;
        color: #4b335a;
        position: absolute;
        left: 10px;
        top: 10px;
      }
      .avatar-photo {
        margin: 20px 0;
        background-color: #fdb00b;
        background-repeat: no-repeat;
        background-size: 60%;
        background-position: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .btn-edit {
        background-color: #26a0a5;
        border: none;
        color: #fff;
        font-size: 10px;
        padding: 3px 10px;
        border-radius: 12px;
        cursor: pointer;
      }
    }
    .input-file {
      position: relative;
      overflow: hidden;
      width: 100%;
      margin-top: 5px;
      height: 60px;
      border: 1px solid #4B335A;
      background-color: #fff;
      border-radius: 3px;
      position: relative;
      cursor: pointer;
      transition: background-color .3s ease;
      .progress {
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: 5px;
        left: 0;
        background-color: #4B335A;
      }
    }
    .input-file:hover {
      background-color: #f2f2f2;
    }
    .input-file [type = file] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    .input-file label {
      font-family: 'arial';
      color: #aaa;
      text-align: left;
      font-size: 14px;
      cursor: pointer;
      left: 20px;
      bottom: 20px;
      position: absolute;
    }
  }
  .input-wrapper, .input-row {
    width: 88%;
  }
  .input-wrapper {
    margin-top: 6px;
  }
  .input-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .input-wrapper {
      flex: 0.45;
      &.flex-1 {
        flex: 0.3;
      }
      &.flex-2 {
        flex: 0.65
      }
    }
  }
}
.label-wrappper-title2 {
  font-size: 18px;
  color: #4b335a;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
.nivel-idioma {
  margin-top: 6px;
  width: 80%;
  justify-content: flex-start;
  display: flex;
  label {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
  }
  input{
    margin-right: 6px;
    &[type="radio"] {
      border: 1px solid #4B335A;
      position: relative;
      background: transparent;
      width: 12px;
      height: 12px;
      border: 1px solid #4B335A;
      border-radius: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      &:checked::after {
        content: '';
        position: absolute;
        background: #4B335A;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        top: 0.976px;
        left: 1.145px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .margin-left {
    margin-left: 25px;
  }
  .form-container.width-middle {
    width: 80%;
  }
  .section-container {
    flex-direction: column;
    align-items: center;
    .input-row {
      flex-direction: column;
      align-items: flex-start;
    }
    .input-wrapper, .input-row {
      width: 98%;
    }
    .input-wrapper {
      margin: 1rem 0;
    }
    .section {
      width: 88%;
      &:nth-child(2) {
        align-items: flex-start;
      }
    }
  }
}
</style>
