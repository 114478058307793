<template>
  <div class="modal-container">
    <div class="modal-form">
      <button-close class="position" @close="closeModal" />
      <slot />
      <div class="panels">
        <div class="panel"></div>
        <div class="panel"></div>
      </div>
      <div class="btn-submit">
        <button-add class="margin-left" @add-register="$emit('add-register')">
          Submeter
        </button-add>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-form',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: String
  },
  components: {
    'button-add': require('@/components/Form/button-add').default,
    'button-close': require('@/components shared/button-close-modal').default
  },
  methods: {
    closeModal () {
      this.$emit('closemodal')
    }
  }
}
</script>

<style lang="scss" scoped>
.panels:empty {
  display: none;
}
.panel:empty {
  display: none;
}
.position {
  position: absolute;
  top: 10px;
  right: 10px;
  @media screen and (min-width: 960px) {
    right: -70px;
    top: -10px;
  }
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #4b335ab4;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    z-index: -1;
  }
  .modal-form {
    position: relative;
    max-width: 800px;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    .btn-submit {
      text-align: right;
    }
  }
}
@media only screen and (max-width: 768px) {
  .modal-container {
    .modal-form {
      width: 92%
    }
  }
}
</style>
