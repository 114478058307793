<template>
  <modal-form @closemodal="$emit('closemodal')" @add-register="addEducacao">
    <div>
      <form-loading v-show="loading" :title="loadingTitle"/>
      <header-title title="Dados Académicos" />
        <div class="section-container">
          <section class="section">
            <div class="input-wrapper">
              <input-form
              title="Curso"
                :error="errors.curso[Object.keys(errors.curso)[0]] ? Object.keys(errors.curso)[0] : ''"
                placeholder=""
                v-model="$v.curso.$model"
                @focus="resetWidget"
              />
            </div>
            <div class="input-wrapper">
              <input-form
                title="Instituição"
                :error="errors.instituicao[Object.keys(errors.instituicao)[0]] ? Object.keys(errors.instituicao)[0] : ''"
                placeholder=""
                v-model="$v.instituicao.$model"
                @focus="resetWidget"
              />
            </div>
          </section>
          <section class="section">
          <div class="input-wrapper">
            <input-datalist
              title="Tipo"
              :error="errors.tipo[Object.keys(errors.tipo)[0]] ? Object.keys(errors.tipo)[0] : ''"
              field="w_isTipo"
              :isShow="w_isTipo"
              @setData="(value) => setData('tipo', value)"
              placeholder=""
              :value="tipo.nome"
              :dataSource="ListasFormulario.grausAcademicos"
              :themeStyle="theme"
              v-on:w_isTipo="toggleWidget"
            />
          </div>
          <div class="input-wrapper">
            <input-datalist
              title="Área de estudo"
              :topAlign="true"
              :error="errors.area[Object.keys(errors.area)[0]] ? Object.keys(errors.area)[0] : ''"
              field="w_isAria"
              :isShow="w_isAria"
              @setData="(value) => setData('area', value)"
              placeholder=""
              :value="area.nome"
              :dataSource="ListasFormulario.areasEstudo"
              :themeStyle="theme"
              v-on:w_isAria="toggleWidget"
            />
          </div>
        </section>
      </div>
    </div>
  </modal-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { getRegisterList, insertEducationProfissional } from '@/api/services/register'
export default {
  name: 'modal-data-academic',
  components: {
    'input-form': require('@/components/Form/input-form').default,
    'input-datalist': require('@/components/Form/input-datalist').default,
    'form-loading': require('@/components/Form/loading').default,
    'modal-form': require('@/components/Form/modal/modal-form').default
  },
  props: {
    endPoint: String,
    form: Object,
    theme: Object
  },
  data () {
    return {
      w_isAria: false,
      w_isTipo: false,
      showModal: false,
      area: '',
      tipo: '',
      hability: '',
      curso: '',
      instituicao: '',
      ListasFormulario: {
        areasEstudo: [],
        grausAcademicos: [],
        habilitacoes: []
      },
      loading: false,
      loadingTitle: '',
      errors: {
        curso: {
          'Curso inválido': false
        },
        instituicao: {
          'Instituição inválida': false
        },
        tipo: {
          'Tipo inváliado': false
        },
        area: {
          'Área de estudo inválido': false
        }
      }
    }
  },
  validations: {
    instituicao: {
      required
    },
    curso: {
      required
    }
  },
  methods: {
    toggleWidget (property, value) {
      this.resetWidget()
      this[property] = !value
    },
    valideField (field) {
      const key = Object.keys(this.errors[field])[0]
      this.errors[field][key] = false
    },
    addEducacao () {
      const { tipo, area, $v, errors } = this
      const { curso, instituicao } = $v
      let ok = true
      Object.keys(this.errors).forEach(field => this.valideField(field))
      if (curso.$model === '' || curso.$error) {
        const property = Object.keys(errors.curso)[0]
        this.errors.curso[property] = true
        ok = false
      }
      if (instituicao.$model === '' || instituicao.$error) {
        const property = Object.keys(errors.instituicao)[0]
        this.errors.instituicao[property] = true
        ok = false
      }
      if (tipo === '') {
        const property = Object.keys(errors.tipo)[0]
        this.errors.tipo[property] = true
        ok = false
      }
      if (area === '') {
        const property = Object.keys(errors.area)[0]
        this.errors.area[property] = true
        ok = false
      }

      if (!ok) {
        return
      }
      if (!tipo && !area && !$v.curso.$model && !$v.instituicao.$model) return
      this.loading = true
      insertEducationProfissional({
        idAreaEstudo: area.id,
        idGrauAcademico: tipo.id,
        designacao: $v.curso.$model,
        instituicaoEnsino: $v.instituicao.$model
      }).then(res => {
        this.loadingTitle = res.data.message
        this.$emit('pushEducacao', res.data.object)
        setTimeout(() => {
          this.$emit('closemodal')
          this.area = ''
          this.tipo = ''
          this.curso = ''
          this.hability = ''
          this.resetWidget()
          this.instituicao = ''
        }, 2000)
      }).catch(() => {
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 3000)
      })
    },
    resetWidget () {
      this.w_isTipo = false
      this.w_isAria = false
    },
    setData (field, value) {
      this[field] = value
      this.resetWidget()
    }
  },
  mounted () {
    getRegisterList().then(res => {
      this.ListasFormulario.areasEstudo = res.data.object.areasEstudo
      this.ListasFormulario.grausAcademicos = res.data.object.grausAcademicos
      this.ListasFormulario.habilitacoes = res.data.object.habilitacoes.map(e => ({ id: e.id, nome: e.designcacao }))
    })
  }
}
</script>

<style lang="scss" scoped>

.separator {
  width: 100%;
  max-width: 1000px;
  height: 1px;
  background-color: #4b335a;
}
.form-container {
  margin-bottom: 50px;
  &.width-middle {
    width: 50%;
    .input-container {
      flex: 0.8;
    }
  }
}
.section-container {
  display: flex;
  justify-content: center;
  width: 100%;
  .section {
    flex: 0.8;
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .input-wrapper, .input-row {
    width: 88%;
  }
  .input-wrapper {
    margin-top: 6px;
  }
  .input-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .input-wrapper {
      flex: 0.45;
      &.flex-1 {
        flex: 0.3;
      }
      &.flex-2 {
        flex: 0.65
      }
    }
  }
}
.label-wrappper-title2 {
  font-size: 18px;
  color: #4b335a;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
@media only screen and (max-width: 768px) {
  .margin-left {
    margin-left: 25px;
  }
  .form-container .width-middle {
    width: 90%;
  }
  .section-container {
    flex-direction: column;
    align-items: center;
    .input-row {
      flex-direction: column;
      align-items: flex-start;
    }
    .input-wrapper, .input-row {
      width: 98%;
    }
    .input-wrapper {
      margin: 1rem 0;
    }
    .section {
      width: 88%;
      &:nth-child(2) {
        align-items: flex-start;
      }
    }
  }
}
</style>
