<template>
  <div class="description-points">
    <h3>{{title}}</h3>
    <div
      v-for="(item, key) in items"
      :key="key"
      class="point-wrapper"
    >
      <div class="circle"></div>
      <p>{{ item.descricao }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'app-description-points',
  props: {
    title: String,
    items: Array
  }
}
</script>
<style lang="scss" scoped>
.description-points{
  margin: 20px 0 0 0;
  flex-direction: column;
  align-items: flex-start;
  flex: unset;
  @media screen and (min-width: 768px) {
    display: flex;
    flex: 1;
  }
  h3{
    margin-bottom: 20px;
    font-size: 14px;
    color: #342041;
  }
  .point-wrapper{
    margin: 5px 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    p{
      color: #707070;
      text-align: left;
      word-wrap: break-word;
      padding-right: 10px;
      margin-left: 10px;
      max-width: 190px;
      font-size: 14px;
      @media(max-width:768px){
        margin-right: 10px;
        text-align: start;
        margin-left: 10px;
        font-size: 14px;
        max-width: unset!important;
      }
    }
  }
  &.improvement{
    .circle {
      background: #26A0A5;
    }
  }
  &.strong {
    .circle {
      background: #342041;
    }
  }
  .circle{
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    left: 8px;
    box-shadow: 1px 1px 6px #0000005C;
    background: #342041;
  }
}
</style>
